import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Row, Col } from "reactstrap"
import { Box } from "ticeboxes"
import { ButtonLink } from "../general/Button"
import { SectionThreeQuery } from "../../../graphql-types"
import { H2, P } from "../general/Typography"
// import useViewPort from "../../hooks/useViewport"
import styled from "styled-components"

interface Props {}

const sectionThreeQuery = graphql`
  query SectionThree {
    markdownRemark(fields: { slug: { eq: "home-pagina" } }) {
      frontmatter {
        section_3_title
        section_3_text
      }
    }
  }
`

const mobileSrc = "/images/uploads/lensonlinebanner-mobile.png"
const desktopSrc = "/images/uploads/lensonlinebanner2-desktop.png"

// const imageSrces = {
//   xs: mobileSrc,
//   sm: desktopSrc,
//   md: desktopSrc,
//   lg: desktopSrc,
//   xl: desktopSrc,
// }

const StyledImg = styled.img`
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 7px 4px rgba(0, 0, 0, 0.08);
`

const SectionThree: React.FC<Props> = () => {
  const data: SectionThreeQuery = useStaticQuery(sectionThreeQuery)
  // const { breakpoint } = useViewPort()

  return (
    <Row className="text-center py-5 mb-5">
      <Col xs="12" sm={{ offset: 1, size: 10 }}>
        <H2 className="mb-4">
          {data.markdownRemark.frontmatter.section_3_title}
        </H2>
      </Col>
      <Col
        xs="12"
        sm={{ offset: 1, size: 10 }}
        md={{ offset: 3, size: 6 }}
        style={{ whiteSpace: "pre-line" }}
      >
        <P className="mb-3">{data.markdownRemark.frontmatter.section_3_text}</P>
      </Col>
      <Col className="pb-4" xs="12" sm={{ offset: 1, size: 10 }}>
        <Box mb="5">
          <StyledImg
            src={desktopSrc}
            alt={data.markdownRemark.frontmatter.section_3_title}
          />
        </Box>
        <ButtonLink to="https://optiek.lensonline.nl/opticien/eye-fashion-leiden/">
          Lens online pagina
        </ButtonLink>
      </Col>
    </Row>
  )
}

export default SectionThree
