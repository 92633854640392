import React from "react"
import { Box } from "ticeboxes"

interface Props {
  src: string
  style?: React.CSSProperties
  backgroundContain?: boolean
  noFilter?: boolean
}

const ImageBackground: React.FC<Props> = ({
  children,
  src,
  backgroundContain,
  noFilter = false,
  style = {},
}) => {
  return (
    <Box
      style={{
        ...style,
        backgroundSize: backgroundContain ? "contain" : "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${src})`,
      }}
    >
      <Box
        style={{
          backgroundColor: noFilter ? "transparent" : "rgba(255,255,255,.55)",
          height: "100%",
          borderRadius: style.borderRadius ? style.borderRadius : 0,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default ImageBackground
