import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import BackgroundImageCard from "./BackgroundImageCard"
import { ServicesQuery, StoresQuery } from "../../../graphql-types"
import { DEFAULT_IMG } from "../../constants"
import generateWinkelServiceLink from "../../utils/generateWinkelServiceLink"
import useViewPort, { BreakPoints } from "../../hooks/useViewport"

const ListContainer = styled.ul`
  display: grid;
  grid-gap: 32px;
  @media (min-width: ${({ theme }) => theme.breakPointMd}px) {
    grid-template-columns: 1fr 1fr;
  }
`

interface Props {
  data: ServicesQuery | StoresQuery
  category: 'winkel' | 'service'
}

const CardList: React.FC<Props> = ({ data, category }) => {
  const { breakpoint } = useViewPort()

  return (
    <ListContainer>
      {data.allMarkdownRemark.edges.map(({ node }, i) => {
        const lastStyles = {
          gridColumnEnd: 3,
        }
        const isLast = i === data.allMarkdownRemark.edges.length - 1

        return (
          <Link
            target={node.frontmatter.override_link ? "_blank" : "_self"}
            to={generateWinkelServiceLink(node, category)}
            style={
              isLast &&
              [BreakPoints.md, BreakPoints.lg, BreakPoints.xl].includes(
                breakpoint
              )
                ? lastStyles
                : {}
            }
          >
            <li>
              <BackgroundImageCard
                imageIsText={node.frontmatter.image_is_text}
                src={
                  node.frontmatter.background_image
                    ? node.frontmatter.background_image
                    : DEFAULT_IMG
                }
              >
                {node.frontmatter.title}
              </BackgroundImageCard>
            </li>
          </Link>
        )
      })}
    </ListContainer>
  )
}

export default CardList
