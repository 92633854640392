import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Box, Flex } from "ticeboxes"
import ImageBackground from "../../general/ImageBackground"
import { H1, P, Span } from "../../general/Typography"
import { Col, Container, Row } from "reactstrap"
import useViewport from "../../../hooks/useViewport"

interface Props {}

const Banner: React.FC<Props> = () => {
  const [image, setImage] = useState("/images/glasses-xs.jpeg")

  const { breakpoint } = useViewport()

  useEffect(() => {
    if (["xs", "sm", "md"].includes(breakpoint)) {
      setImage("/images/glasses-xs.jpeg")
    } else {
      setImage("/images/glasses-lg.jpeg")
    }
  }, [breakpoint])

  return (
    <ImageBackground src={image}>
      <Container>
        <Row>
          <Col>
            <Flex justify-content="center" justify-content-md="start">
              <Box py="5" mt-md="4">
                <H1 upcase className="text-center">
                  <Span weight="bold">Eye</Span>
                  <Span weight="lighter">fashion</Span>
                </H1>
                <H1 className="text-center" fontSize="small" weight="lighter">
                  By Rami
                </H1>
              </Box>
            </Flex>
          </Col>
        </Row>
        <div className="d-none d-md-block" style={{ height: 100 }}></div>
        <Row>
          <Col>
            <Box d-md-flex justify-content-md-between py="5" text-center>
              <a href="#footer">
                <P className="mb-4" upcase>
                  Openingstijden
                </P>
              </a>
              <Link to="/service">
                <P className="mb-4" upcase>
                  Service
                </P>
              </Link>
              <Link to="/winkel/brillen">
                <P className="mb-4" upcase>
                  Monturen
                </P>
              </Link>
            </Box>
          </Col>
        </Row>
      </Container>
    </ImageBackground>
  )
}

export default Banner
