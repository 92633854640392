import React from "react"
import { Row, Col } from "reactstrap"
import { ButtonLink } from "../general/Button"
import { H2, P } from "../general/Typography"
import styled from 'styled-components'

const Img = styled.img`
  max-width: 200px;
  @media (min-width: ${({ theme }) => theme.breakPointMd}px) {
    max-width: 250px;
  }
`

interface Props { }

const SectionSprayPay: React.FC<Props> = () => {
  return (
    <Row className="text-center py-5 text-center">
      <Col xs="12">
        <H2 className="mb-3">
          Betaal bij ons gespreid met SprayPay
        </H2>

        <Img className="mb-1" src="/images/spraypay.png" alt="Betaal gespreid met SprayPay" />
      </Col>
      <Col
        className="pb-4"
        xs="12"
        sm={{ offset: 1, size: 10 }}
        md={{ offset: 3, size: 6 }}
        style={{ whiteSpace: "pre-line" }}
      >
        <P className="mb-5">
          Wil je graag nu al profiteren van beter zicht door middel van een bril of contactlenzen, maar komt het financieel even niet uit? Betaal bij ons dan gespreid met de hulp van SprayPay.
        </P>
        <ButtonLink target="_blank" to="https://www.spraypay.nl/">Lees meer over SprayPay</ButtonLink>
      </Col>
    </Row>
  )
}

export default SectionSprayPay
