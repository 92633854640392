import React from "react"

interface Props {}

const js = `
if (window.netlifyIdentity) {
    window.netlifyIdentity.on("init", user => {
      if (!user) {
        window.netlifyIdentity.on("login", () => {
          document.location.href = "/admin/";
        });
      }
    });
  }
`

const RedirectUserToAdminOnCMSLogin: React.FC<Props> = () => {
  return <script dangerouslySetInnerHTML={{ __html: js }}></script>
}

export default RedirectUserToAdminOnCMSLogin
