import React, { CSSProperties } from "react"
import { Flex } from "ticeboxes"
import ImageBackground from "./ImageBackground"
import { H2 } from "./Typography"

interface Props {
  src?: string
  imageIsText?: boolean
  height?: number
  style?: CSSProperties
}

const BackgroundImageCard: React.FC<Props> = ({
  children,
  src,
  imageIsText,
  height,
  style,
}) => {
  return (
    <ImageBackground
      style={{
        textAlign: "center",
        borderRadius: 25,
        boxShadow: "0 5px 7px rgba(0,0,0,.1)",
        ...style,
      }}
      src={src}
      backgroundContain={imageIsText}
      noFilter={imageIsText}
    >
      <Flex
        align-items="center"
        justify-content="center"
        style={{ height: height ?? 115 }}
      >
        {imageIsText ? <></> : <H2 className="m-0">{children}</H2>}
      </Flex>
    </ImageBackground>
  )
}

export default BackgroundImageCard
