import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Row, Col } from "reactstrap"
import { H2, P } from "../general/Typography"
import { SectionTwoQuery } from "../../../graphql-types"
import { ButtonLink } from "../general/Button"

interface Props {}

const sectionTwoQuery = graphql`
  query SectionTwo {
    markdownRemark(fields: { slug: { eq: "home-pagina" } }) {
      frontmatter {
        section_2_title
        section_2_text
      }
    }
  }
`

const SectionTwo: React.FC<Props> = () => {
  const data: SectionTwoQuery = useStaticQuery(sectionTwoQuery)

  return (
    <Row className="text-center py-5">
      <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
        <H2 className="mb-4">
          {data.markdownRemark.frontmatter.section_2_title}
        </H2>
      </Col>
      <Col
        className="pb-4"
        xs="12"
        sm={{ offset: 1, size: 10 }}
        md={{ offset: 3, size: 6 }}
        style={{ whiteSpace: "pre-line" }}
      >
        <P className="mb-5">{data.markdownRemark.frontmatter.section_2_text}</P>
        <ButtonLink target="_blank" to="/online-bril-passen">
          Pas nu je bril online!
        </ButtonLink>
      </Col>
    </Row>
  )
}

export default SectionTwo
