import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Row, Col } from "reactstrap"
import { Box } from "ticeboxes"
import { H2, P } from "../general/Typography"
import { SectionOneQuery } from "../../../graphql-types"
import AfspraakButton from "../general/AfspraakButton"

interface Props {}

const sectionOneQuery = graphql`
  query SectionOne {
    markdownRemark(fields: { slug: { eq: "home-pagina" } }) {
      frontmatter {
        section_1_title
        section_1_text
        section_1_photo
      }
    }
  }
`

const SectionOne: React.FC<Props> = () => {
  const data: SectionOneQuery = useStaticQuery(sectionOneQuery)

  return (
    <Row className="text-center py-5 my-md-4 text-center text-md-left">
      <Col xs="12">
        <H2 className="mb-5">
          {data.markdownRemark.frontmatter.section_1_title}
        </H2>
      </Col>
      <Col xs="12" md="6" style={{ whiteSpace: "pre-line" }}>
        <Box mb="5">
          <P>{data.markdownRemark.frontmatter.section_1_text}</P>
        </Box>
        <Box className="d-none d-md-block" my="5" pb="4">
          <AfspraakButton text="Maak nu een afspraak!" />
        </Box>
      </Col>
      <Col xs="12" md={{ offset: 1, size: 5 }}>
        <img
          style={{ width: "100%" }}
          src={data.markdownRemark.frontmatter.section_1_photo}
          alt="Eyefashion Pand"
        />
      </Col>
      <Col xs="12" className="d-md-none">
        <Box mt="5">
          <AfspraakButton text="Maak nu een afspraak!" />
        </Box>
      </Col>
    </Row>
  )
}

export default SectionOne
