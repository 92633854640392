import React from "react"
import Layout from "../components/layout/Layout"
import Banner from "../components/home/banner/Banner"
import { Container } from "reactstrap"
import Head from "../components/layout/Head"
import RedirectUserToAdminOnCMSLogin from "../components/admin/RedirectUserToAdminOnCMSLogin"
import { graphql, useStaticQuery } from "gatsby"
import { SiteDescriptionQuery } from "../../graphql-types"
import SectionOne from "../components/home/SectionOne"
import SectionTwo from "../components/home/SectionTwo"
import { Box } from "ticeboxes"
import WinkelCardList from "../components/winkel/WinkelCardList"
import SectionThree from "../components/home/SectionThree"
import SectionSprayPay from "../components/home/SectionSprayPay"

interface Props { }

const siteDescriptionQuery = graphql`
  query SiteDescription {
    markdownRemark(fields: { slug: { eq: "home-pagina" } }) {
      frontmatter {
        description
      }
    }
  }
`

const index: React.FC<Props> = () => {
  const siteInfo: SiteDescriptionQuery = useStaticQuery(siteDescriptionQuery)

  return (
    <>
      <Head
        title="Home"
        description={siteInfo.markdownRemark.frontmatter.description}
      >
        {/* Identity widget from Netlify to handle CMS Auth */}
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
      </Head>

      <Layout>
        <Banner />
        <Container>
          <SectionOne />
          <SectionSprayPay />
          <Box my="5">
            <WinkelCardList />
          </Box>
          <SectionTwo />
          <SectionThree />
        </Container>
        <RedirectUserToAdminOnCMSLogin />
      </Layout>
    </>
  )
}

export default index
