import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { StoresQuery } from "../../../graphql-types"
import CardList from "../general/CardList"

interface Props {}

const storesQuery = graphql`
  query Stores {
    allMarkdownRemark(filter: { fields: { type: { eq: "_store" } } }) {
      edges {
        node {
          frontmatter {
            title
            background_image
            is_active
            override_link
            image_is_text
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

const WinkelCardList: React.FC<Props> = () => {
  const data: StoresQuery = useStaticQuery(storesQuery)

  return <CardList data={data} category="winkel" />
}

export default WinkelCardList
